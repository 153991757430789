
'use strict';
class Slajder {
  constructor() {
    (function($) {
      $(document).ready(() => {
        $('#slajder .slajder__owl').owlCarousel({
          dots: false,
          loop: true,
          nav: true,
          lazyLoad: false,
          smartSpeed: 1400,
          autoplay: false,
          rewind: false,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          navText: ['<', '>'],
          items: 1,
          animateOut: 'fadeOut'
        });
      });
    })(jQuery);
  }
}
class Personel {
  constructor() {
    (function($) {
      $(document).ready(() => {
        $('.zespol__wrap header h2').unbind().on('click', function() {
          console.log('test');
          if ($(this).parents().eq(1).hasClass('show')) {
            $('.zespol__wrap .block-views').removeClass('show');
          } else {
            $('.zespol__wrap .block-views').removeClass('show');
            $(this).parents().eq(1).addClass('show');
          }
        });
      });
    })(jQuery);
  }
}

class Menu {
  constructor() {
    (function($) {
      $(document).ready(() => {
        $('.menu__trigger').unbind().on('click', function() {
          $('body').toggleClass('open__menu');
        });
        $('.blok__tytul').unbind().on('click', function() {
          if ($(this).parent().hasClass('show')) {
            $('.blok').removeClass('show');
          } else {
            $('.blok').removeClass('show');
            $(this).parent().addClass('show');
            $(window).scrollTop($('.show').offset().top - 200);
          }
        });
        $('.body__rozwin').unbind().on('click', function() {
          $(this).parent().toggleClass('rozwin');
        });
        $('.dziedzina__nazwa').unbind().on('click', function() {
          if ($(this).parent().hasClass('show')) {
            $('.cennik__dziedzina').removeClass('show');
          } else {
            $('.cennik__dziedzina').removeClass('show');
            $(this).parent().addClass('show');
            $(window).scrollTop($('.show').offset().top - 200);
          }
        });
      });
    })(jQuery);
  }
}


class MenuHide {
  constructor() {
    (function($) {
      $(document).ready(() => {
        var navbarHeight = $('#header').outerHeight();
        var previousScroll = 0;
        $(window).scroll(function() {
          var currentScroll = $(this).scrollTop();
          if (currentScroll > previousScroll && currentScroll > navbarHeight) {
            $('#header').removeClass('up').addClass('down');
          } else {
            if (currentScroll + $(window).height() < $(document).height()) {
              $('#header').removeClass('down').addClass('up');
            }
          }
          previousScroll = currentScroll;
        });
      });
    })(jQuery);
  }
}

class Ankieta {
  constructor() {
    (function($) {
      $(document).ready(() => {
        $('.form-item input').change(function() {
          var c = this.checked ? 'zaznaczone' : 'puste';
          $(this).parents().eq(2).addClass(c);
        });
        $('.form-item textarea').change(function() {
          if ($.trim($('.form-item textarea').val()).length < 1) {
            console.log('empty');
            $(this).parents().eq(1).removeClass('zaznaczone');
          } else {
            console.log('dziala textarea');
            $(this).parents().eq(1).addClass('zaznaczone');
          }
        });
      });
    })(jQuery);
  }
}

class SmoothDestroy {
  constructor() {
    this.init();
  }

  init() {

    function isChrome() {
      var isChromium = window.chrome,
        winNav = window.navigator,
        vendorName = winNav.vendor,
        isOpera = winNav.userAgent.indexOf("OPR") > -1,
        isIEedge = winNav.userAgent.indexOf("Edge") > -1,
        isIOSChrome = winNav.userAgent.match("CriOS");

      return (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false);
    }

    if (!isChrome()) {
      SmoothScroll.destroy();
    }
  }
}


var smoothDestroy = new SmoothDestroy();
var ankieta: Ankieta = new Ankieta();
var menuhide: MenuHide = new MenuHide();
var menu: Menu = new Menu();
var slajder: Slajder = new Slajder();
var personel: Personel = new Personel();
